<template>
  <div :id="stepId" class="cw-application">
    <v-card v-if="identified" class="cw-application__content pa-0">
      <v-stepper
        v-if="hasApplicationSteps"
        v-model="currentStepIndex"
        :class="productClass"
        class="elevation-0"
      >
        <v-stepper-header>
          <template v-for="(step, index) in steps">
            <v-stepper-step
              :key="step.name"
              :step="index + 1"
              :complete="currentStepIndex > index + 1"
            >
              <span v-if="steps.length <= 7 || $vuetify.breakpoint.lgAndUp">{{ step.text }}</span>
            </v-stepper-step>
            <v-divider v-if="index < steps.length - 1" :key="index"/>
          </template>
        </v-stepper-header>
        <v-slide-y-transition origin="top center" mode="out-in">
          <router-view/>
        </v-slide-y-transition>
      </v-stepper>
    </v-card>
    <component
      :is="footerComponent"
      v-if="footerComponent"
    />
    <cw-dialog
      v-if="!exceptions.includes('IDENTIFICATION__INTERRUPTED')"
      :data="dialogData"
    >
      <component
        :is="dialogContent"
        v-bind="dialogProps"
      />
    </cw-dialog>
    <div id="cw-application__click-trap"/>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapState } from 'vuex';
import colors from 'vuetify/es5/util/colors';
import { getWorkflowForProduct } from '../store/utils/api';
import { sleep } from '../utils/sleep';

const {
  VUE_APP_OPTIMIZE_ID_VIPPI,
  VUE_APP_OPTIMIZE_ID_SALDO,
} = process.env;

const saldo = {
  name: 'saldo',
  accent: colors.red.accent2,
  error: '#f74936',
  info: colors.blue.base,
  primary: '#7a37d2',
  secondary: '#370b45',
  success: colors.lightGreen.base,
  warning: '#9c1F11',
};

const vippi = {
  name: 'vippi',
  accent: colors.red.accent2,
  error: colors.red.base,
  info: colors.blue.base,
  primary: colors.lightBlue.base,
  secondary: colors.amber.darken1,
  success: colors.lightGreen.base,
  warning: colors.amber.darken3,
};

export default {
  name: 'CwApplication',

  components: {
    'cw-footer-fi': () => import('@fi/components/Footer'),
    'cw-footer-se': () => import('@se/components/Footer'),
    'cw-dialog': () => import('@shared/components/Dialog'),
    'cw-tupas-buttons-container': () => import('@shared/components/TupasButtonsContainer'),
  },

  beforeRouteEnter(to, from, next) {
    next(vm => vm.initializeApplication(to));
  },

  beforeRouteUpdate(to, from, next) {
    this.handleStepChange(to.params.action);
    this.initializeApplication(to);
    this.$wait.end('SUBMIT_FORM');
    next();
  },

  data: () => ({
    currentStepIndex: 0,
    dialogContent: '',
    dialogData: { show: false },
    dialogProps: {},
    sellerSteps: [],
    soloSteps: [],
    stepId: 'default',
    steps: [],
    workflow: '',
  }),

  computed: {
    ...mapGetters({
      country: 'application/country',
      requireIdentification: 'application/requireIdentification',
      identified: 'application/identified',
      hashId: 'application/hashId',
    }),

    ...mapState({
      exceptions: state => state.exceptions,
      loanType: state => state.application.product.loanType,
      product: state => state.application.product,
      initialData: state => state.application.initialData,
      selectedOffer: state => state.application.selectedOffer,
      productName: state => state.application.product.productName,
    }),

    hasApplicationSteps() {
      return Object.keys(this.steps).length;
    },

    footerComponent() {
      if (!this.country) return null;

      return `cw-footer-${this.country}`;
    },

    optimizeId() {
      if (this.product && this.product.brand === 'vippi') return VUE_APP_OPTIMIZE_ID_VIPPI;

      return VUE_APP_OPTIMIZE_ID_SALDO;
    },

    productClass() {
      if (!this.product || !this.product.brand) return '';
      return this.product.brand.toLowerCase();
    },
  },

  watch: {
    /**
     * Watch for exceptions in the store
     */
    exceptions: {
      handler(e) {
        if (!e.length) return false;

        const options = {};

        /**
         * Application exists
         */
        if (e.includes('APPLICATION__UNFINISHED')) {
          options.component = 'DialogApplicationUnfinished';
        }

        /**
         * Confirmation code rejected
         */
        if (e.includes('COMPANY__NOT_FOUND')) {
          options.component = 'DialogCompanyNotFound';
          options.folder = 'fi';
        }

        /**
         * Confirmation code rejected
         */
        if (e.includes('CONFIRMATION_CODE__REJECTED')) {
          options.component = 'DialogConfirmationCodeRejected';
        }

        /**
         * Technical Failure
         */
        if (e.includes('FAILURE__TECHNICAL')) {
          options.component = 'DialogFailureTechnical';
        }

        /**
         * Identification interrupted
         */
        if (e.includes('IDENTIFICATION__INTERRUPTED')) {
          options.component = 'DialogIdentificationInterrupted';
        }

        this.$bus.$emit('OPEN_CONFIRMATION_DIALOG', options);
        return false;
      },
      immediate: true,
    },

    requireIdentification: {
      handler(value) {
        if (value.requirement) {
          this.initializeDialog('cw-tupas-buttons-container', value.url);
        }
      },
      deep: true,
      immediate: true,
    },
  },

  async created() {
    await this.getInitialWorkflow();
    this.addGoogleOptimizeScript();
    // build steps based on the loan type
    await this.setApplicationSteps();
    const { action } = this.$store.state.route.params;
    this.setStepId(action);
  },

  mounted() {
    if (this.product.brand === 'Vippi') {
      this.$vuetify.theme.themes.light = vippi;
    } else {
      this.$vuetify.theme.themes.light = saldo;
    }
    const { action } = this.$store.state.route.params;
    this.handleStepChange(action, action);
  },

  methods: {
    ...mapActions({
      removeException: 'removeException',
      updateWorkflow: 'application/updateWorkflow',
    }),
    ...mapGetters({
      isBrokered: 'application/isBrokered',
      isLender: 'application/isLender',
      getStateWorkflow: 'application/getStateWorkflow', // from state
      getWorkflowType: 'application/getWorkflowType', // from app
    }),

    async getInitialWorkflow() {
      if (this.getStateWorkflow() === 'test') {
        return this.getStateWorkflow();
      }
      if (!this.workflow && !this.hashId) {
        return this.getStateWorkflow();
      }
      if (!this.workflow || this.workflow === '') {
        const prm = await getWorkflowForProduct(this.productName);
        await Promise.resolve(prm).then(async (res) => {
          await sleep(100);
          await this.updateWorkflow(res.data.flow);
        });
        if (!this.isBrokered() && !this.isLender()) {
          await sleep(1000);
        }
      }
      return this.getStateWorkflow();
    },

    addGoogleOptimizeScript() {
      const script = document.createElement('script');

      script.setAttribute('src', `https://www.googleoptimize.com/optimize.js?id=${this.optimizeId}`);

      document.head.insertBefore(script, document.head.firstElementChild);
    },

    /**
     * Adds a new step either in the end of the array or in the designated index
     *
     * @param {Object} step       - Step data
     * @param {String} step.name  - Name of the step
     * @param {String} step.text  - Human readable text of the step
     * @param {Number} [position] - Position the new step should be inserted in
     */
    addStep(step, position = null) {
      const stepPosition = position === null ? this.steps.length : position;
      this.steps.splice(stepPosition, this.steps.findIndex(s => s.name === step.name), step);
    },

    async setApplicationSteps() {
      const imports = {
        fi: () => import('@fi/pages/data'),
        se: () => import('@se/pages/data'),
      };
      const {
        applicationSteps,
        sellerSteps,
        soloSteps,
      } = await imports[this.country]();
      const steps = applicationSteps[await this.decideLoanTypeForSteps(this.loanType)];

      this.steps = steps[this.initialData.processRole] || steps.initial;
      this.sellerSteps = sellerSteps;
      this.soloSteps = soloSteps;
    },

    async decideLoanTypeForSteps(loanType) {
      let type = loanType;
      const appWf = this.getWorkflowType();
      if (appWf && appWf === 'broker') {
        // check application first
        type = `brokered${loanType.charAt(0).toUpperCase()}${loanType.slice(1)}`;
      } else {
        // then try state
        await this.getInitialWorkflow();
        if (this.isBrokered()) {
          type = `brokered${loanType.charAt(0).toUpperCase()}${loanType.slice(1)}`;
        }
      }
      return type;
    },

    async handleStepChange(name, text = '') {
      await this.$nextTick();
      await this.setApplicationSteps();

      const isDownsell = () => this.selectedOffer
        && this.selectedOffer.type === 'fixedPeriodContinuousLoan'
        && this.loanType === 'instalmentLoan';

      const nextStepName = isDownsell() ? 'instalmentLoanComplete' : name;

      let nextStepIndex = this.steps.findIndex(step => step.name === nextStepName);

      if (nextStepIndex === -1) {
        const soloStep = this.soloSteps.filter(s => s.name === name);

        if (soloStep.length) {
          this.currentStepIndex = 1;
          this.steps = soloStep;
        } else if (name === 'salesDetails' || name === 'complete') {
          this.steps = this.sellerSteps;
          nextStepIndex = this.steps.findIndex(step => step.name === name);
          this.currentStepIndex = nextStepIndex + 1;
        } else {
          this.currentStepIndex += 1;
          this.addStep({ name, text }, this.currentStepIndex);
        }
      } else {
        this.currentStepIndex = nextStepIndex + 1;
      }
    },

    /**
     * 1) Initialize application with saved values
     * 2) Push data to datalayer
     *
     * @param {Object} to - Destination route
     */
    initializeApplication(to) {
      this.setStepId(to.params.action);
      if (this.product.loanType !== 'carInstalmentLoan') {
        this.$dataLayer.PushToECommerce({ params: to.params });
      }
    },

    /**
     * Initialize a dialog with desired content.
     *
     * @param {String} component - component to be shown inside the dialog.
     * @param {String} route - return route for tupas identification.
     */
    initializeDialog(component, route) {
      // current component to be viewed in the dialog
      this.dialogContent = component;
      this.dialogProps.route = route;
      this.dialogData.show = true;
    },

    /**
     * Set id of the root dom element
     */
    setStepId(action) {
      this.stepId = `cw-application__${action}`;
    },
  },
};
</script>

<style lang="scss">
.cw-application {

  &__content {
    @include mq($until: sm) {
      margin: 8px;
    }

    @include mq($from: sm) {
      margin: 16px;
    }
  }

  &--error {
    position: relative;
    font-size: 12px;
    top: 0;
    left: 0;
    min-height: 18px;
  }

  .input-group--select.primary--text {
    span {
      color: rgba(0, 0, 0, 0.87);
    }
  }

  &__table {
    > table {
      border-spacing: 0;
      width: 100%;
    }

    td {
      line-height: 36px !important;
      padding: 0 12px !important;

      &.align-top {
        vertical-align: top;
      }

      img,
      svg {
        max-width: 120px;
        max-height: 14px;
        vertical-align: middle;
      }
    }

    tr:nth-child(odd) {
      background-color: rgba(0, 0, 0, 0.025);
    }
  }

  &__subtitle {
    margin-bottom: 0;

    @include mq($until: sm) {
      padding-left: 48px;
    }

    @include mq($from: sm, $until: md) {
      padding-left: 64px;
    }

    @include mq($from: md) {
      padding-left: 72px;
    }
  }

  .v-stepper__header {
    position: relative;
  }

  .saldo {
    .v-stepper__step--active:not(.v-stepper__step--error) .v-stepper__step__step {
      background-color: #7a37d2 !important;
    }
   .v-btn.purple-one {
      padding: 16px 134px !important;
      text-transform: none;
    }
    .v-application .error--text {
      color: #9C1F11;
      caret-color: #9C1F11;
    }
    :root {
      --cc-body-font: PPRightProtestTextRegular, sans-serif !important;
      --cc-heading-font: PPRightProtestWideBlack, sans-serif !important;
    }

    .v-application {
      font-family: PPRightProtestTextRegular, sans-serif;
      .text-caption {
        font-family: PPRightProtestTextRegular, sans-serif;
      }
    }
    .purple-one {
      background-color: #7a37d2 !important;
    }

    .purple-one--text {
      color: #7a37d2 !important;
    }

    .footer-logo {
      img {
        max-width: 85%;
      }
    }
  }

  .vippi {
    .v-stepper__step--active:not(.v-stepper__step--error) .v-stepper__step__step {
      background-color: #23319b !important;
    }
    .purple-one {
      background-color: #ffa000 !important;
    }

    .purple-one--text {
      color: #ffa000 !important;
    }

    .cw-offer-price-card:before {
      background-color: #c25406 !important;;
    }

    .cw-offer-price-card:after {
      background-color: #ee6608 !important;
    }
  }
}

.dark-shadow {
  box-shadow: inset 0 100px 150px -65px rgba(0, 0, 0, 0.4);
}

#cw-application__click-trap {
  height: 1px;
  position: fixed;
  right: 0;
  top: 0;
  width: 1px;
  z-index: 1000;
}

.cw-footer {
  padding: 16px;

  @include mq($from: md) {
    padding: 32px;
  }

  &__list {
    list-style: none;
    padding: 0;

    &--top {
      margin-right: 24px;
    }

    li {
      line-height: 24px;
      max-width: 300px;
    }
  }

  .avi-logo {
    margin: 24px 0 16px;

    @include mq($from: md) {
      margin: 0;
      text-align: right;
    }
  }
}

.application-frame {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
}

</style>
